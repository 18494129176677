import React, { Component } from 'react';
import logo from '../../../src/images/defaultUser-image.jpg';
import memberApp from '../../../src/images/dashboard/memberappwhite.png'
import { NavLink, Navigate } from 'react-router-dom';
// import logo from '../../src/images/dashboard/admin-img.png';
// import memberApp from '../../src/images/dashboard/memberappwhite.png'
import { userInfo } from "../../helper/service";
import NewLogo from '../../../src/images/new-logo.png';
import { io } from "socket.io-client";
// import socketData from '../../reducers/socketReducer';
import NotificationDropDown from './NotificationDropDown';
import ConfirmationModal from '../Setting/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';


export interface HeaderProps {

}

export interface HeaderState {
  firstName?: string;
  lastName?: string;
  profileImage?: string;
  is_redirect?: Boolean;
  role?: string;
  socketData?: any;
  message?: string;
  warningModalTitle?: string;
  modalType?: string;
  showWarningModal?: Boolean;
  setLogout?: Boolean;
}

export default class Header extends React.Component<HeaderProps, HeaderState> {

  constructor(props: any) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      profileImage: '',
      socketData: [],
      role: '',
      is_redirect: false,
      message: '',
      warningModalTitle: '',
      modalType: '',
      showWarningModal: false,
      setLogout: false
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem('user_id');
    let backendRunningPort = (process.env.REACT_APP_BACKEND_API_URL)?.split("9000");
    let backendRunningPortId;
    if (backendRunningPort) {
      backendRunningPortId = `${backendRunningPort[0]}` + "9000"
    }
    const socket = io(`${backendRunningPortId}`);
    console.log(socket, "socket")
    if (socket) {
      this.setState({ socketData: socket })
    }

    try {
      userInfo(userId).then(data => {
        //console.log("user info",data)
        var systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //console.log(systemTimeZone,"systemTimeZone systemTimeZone")
        localStorage.setItem("websiteTimeZone", data?.result?.validUser?.timezone ? data?.result?.validUser?.timezone : systemTimeZone)
        this.setState({
          firstName: data.result.validUser.firstName,
          lastName: data.result.validUser.lastName,
          role: data.result.validUser.role
        })
        if (data.result.validUser.company.length > 0) {
          localStorage.setItem('compName', data.result.validUser.company[0].name);

          localStorage.setItem('logoImage', data.result.validUser.image);
          localStorage.setItem('membershipImage', data.result.validUser.logo);

        }
        if (data.result.validUser.company.length > 0) {
          if (data.result.validUser) {
            var profileImage: any = data.result.validUser.image;
          } else {
            var profileImage: any = logo;
          }
        } else {
          var profileImage: any = logo;
        }

        this.setState({ profileImage: profileImage })
        let userType = "Partner"
        this.state.socketData.emit("newUser", data.result.validUser._id, userType)
        // this.state.socketData.emit("getNotification",data.result.validUser._id,userType)
        // this.state.socketData.on("sendNotification",data1=>{
        //   console.log("send notification",data1)
        // })
      }).catch((err) => {
        this.setState({ profileImage: logo })
      });
    } catch (err) {
      this.setState({
        message: "Something went wrong.",
        warningModalTitle: "ERROR!!!",
        modalType: 'error',
        showWarningModal: true,
      });
    }
  }

  logout() {
    localStorage.clear();
    this.setState({ is_redirect: true })
  }

  render() {
    let uid = localStorage.getItem('user_id');
    let is_verify = localStorage.getItem('is_verify');

    if (this.state.is_redirect || !uid || !is_verify) {
      return (<Navigate to="/login" />);
    }

    var bgcolor = localStorage.getItem('bgcolor');
    var color = localStorage.getItem('bgcolor');

    if (bgcolor) {
      bgcolor = bgcolor;
    } else {
      bgcolor = "#3F53A528";
    }

    if (color) {
      color = color;
    } else {
      color = "#3F53A528";
    }


    const colors = {
      color: color,
    };
    const bgcol = {
      backgroundColor: "#3F53A528"
    }

    let base64 = localStorage.getItem('image');
    var imgs: any;
    if (this.state.profileImage) {
      imgs = this.state.profileImage;
    } else {
      imgs = logo;
    }

    return (
      <nav className="main-header navbar navbar-expand">
        {this.state.showWarningModal && (
          <ConfirmationModal
            type={this.state.modalType}
            title={this.state.warningModalTitle}
            message={this.state.message}
            onConfirm={() => {
              this.setState({
                showWarningModal: false,
                message: '',
                warningModalTitle: '',
                modalType: '',
              })
              if (this.state.setLogout) {
                this.logout();
              }
            }}
            onCancel={() => this.setState({ showWarningModal: false, message: '', warningModalTitle: '', modalType: '', setLogout: false })}
          />
        )}
        {/* Left navbar links */}
        {/* <ul className="navbar-nav">
                <li className="nav-item d-lg-none">
                  <a className="nav-link collapse-sidebar" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>      
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <a className="nav-link" href="#" >
                    <img src={memberApp} alt="" />
                  </a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <a href="#" className="nav-link" ><i className="fas fa-ellipsis-v" /></a>
                </li>
              </ul> */}
        {/* Right navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item d-lg-none">
            <a
              className="nav-link collapse-sidebar"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
          <li className="nav-item">
            <div className='d-flex text-white align-items-center gap-2'>
              <a className="nav-link p-0 pl-2" href="/dashboard" role="button">
                <img src={NewLogo} alt="logo" width={80} />
              </a>
              <div className="dropdown admin-courses for-drop-down" >
                <FontAwesomeIcon icon={faEllipsisVertical} style={{ height: "25px", marginTop: "10px" }}
                  className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                />
                <div className="dropdown-menu mt-0" aria-labelledby="dropdownMenuButton" style={{ minWidth: "fit-content" }}>
                  <a className="dropdown-item" href="https://wisdome.com.au/legal-tcs-use/" target='blank' style={{ fontSize: "12px", lineHeight: "15px" }}>Terms and Conditions</a>
                  <a className="dropdown-item" href="https://wisdome.site/login" target='blank' style={{ fontSize: "12px", lineHeight: "15px" }}>Wisdome HUB</a>
                  <a className="dropdown-item" href="https://help.wisdome.com.au/" target="blank" style={{ fontSize: "12px", lineHeight: "15px" }}>Support</a>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}

          {/* <li className="nav-item">
            <a className="nav-link" data-widget="navbar-search" href="#" role="button" >
              <i className="fas fa-search" />
            </a>
            <div className="navbar-search-block">
              <form className="form-inline">
                <div className="input-group input-group-sm">
                  <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search" />
                    </button>
                    <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li> */}
          {/* Notifications Dropdown Menu */}
          <li className="nav-item dropdown" key="asdf-1">
            <NotificationDropDown />
            {/* <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">0 Notifications</span>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2" /> 0 new messages
                <span className="float-right text-muted text-sm">0 mins</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2" /> 0 friend requests
                <span className="float-right text-muted text-sm">0 hours</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2" /> 0 new reports
                <span className="float-right text-muted text-sm">0 days</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
            </div> */}
          </li>
          <li className="nav-item" key="asdf-2">
            <p className="hidden-xs nav-link user-name">
              {this.state.firstName} {this.state.lastName} <br />
              <small>{this.state.role} </small>
            </p>
          </li>
          <li className="nav-item dropdown user user-menu" key="asdf-3">
            <img src={imgs} className="user-image" alt="User Image" />
          </li>
          <li className="nav-item dropdown user user-menu" key="asdf-4">
            <a href="#" className="nav-link" data-toggle="dropdown">
              <i className="fas fa-angle-down" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              {/* <a href="#" className="dropdown-item">Business</a>
                    <a href="#" className="dropdown-item">Edit Appearance</a>
                    <a href="#" className="dropdown-item">Personal Profile </a>
                    <a href="#" className="dropdown-item">Account settings </a>
                    <a href="#" className="dropdown-item">Statistics</a> */}
              <NavLink to="/settings/company" className="dropdown-item">
                Edit Appearance
              </NavLink>
              <NavLink to="/settings/account" className="dropdown-item">
                Account settings
              </NavLink>
              <a
                href="#"
                className="dropdown-item"
                onClick={() => {
                  this.setState({
                    message: "Are you sure you want to logout?",
                    showWarningModal: true,
                    setLogout: true,
                    modalType: "logout",
                    warningModalTitle: "Logout Confirmation",
                  })
                }}
              >
                Log Out
              </a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}
