import React, { useState, useEffect } from "react";
import { useLocation, useNavigate ,useParams} from "react-router-dom";
import { Form, Badge } from "react-bootstrap";
import * as Yup from "yup";

import Header from "../Common/Header";
import NewSidebar from "../new-sidebar";
import Loader from "../../Loader";
import SuccessPopup from "../Setting/Sucesspopup";
import ConfirmationModal from "../Setting/ConfirmationModal";

import {
  getRecentCourses,
  getAllsubscription,
  getAllMemberThatBuyMembership,
  getRecentChallenges,
  getAcceptIndividualPayEvents,
  getAllEvents,
  getEmailTemplate,
  saveEmailAutomation,
  getAnAutomation,
  updateEmailAutomation,
} from "../../helper/service.ts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleLeft,
  faClock,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const EmailAutomation = () => {
  const navigate = useNavigate();
  const {id} = useParams(); 
  const [selectedTrigger, setSelectedTrigger] = useState({name:"Select Condition",value:"Select Condition"});
  const dropdownOptions = [
    {
      option: "Contact enrolls for a Course",
      index: "Courses"
    },
    {
      option: "Contact enrolls for a Challenge",
      index: "Challenges"
    },
    {
      option: "Contact registers for an Event",
      index: "Event"
    },
    {
      option: "Contact subscribes to a Membership",
      index: "SubAdminSubs"
    }
  ];
  
  const [selectedSection, setSelectedSection] = useState("groups");
  const [allTemplates, setAllTemplate] = useState("");
  const [automationName, setAutomationName] = useState('');

  const [delayTime, setDelayTime] = useState("");
  const [delayCount, setDelayCount] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [groupData, setGroupsData] = useState(null);
  const [selectedIds, setAllSelectedIds] = useState([]);

  const [subsLevel, setSubsLevel] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [challengesList, setChallengesList] = useState([]);

  const [selectedSubsLevels, setSelectedSubsLevels] = useState([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [selectedChallengeIds, setSelectedChallengeIds] = useState([]);
  const [selectedEventIds, setSelectedEventIds] = useState([]);

  const [showWarningModal, setWarningModal] = useState(false);
  const [mdlMessage, setMdlMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isEditing,setIsEditing] = useState(false);
  useEffect(()=>{
    if(id){
      getAutomationDetail(id);
    }
  },[id])
  const getAutomationDetail = async (id) => {
    setIsLoader(true);
    await getAnAutomation(id).then(async (res) => {
      if (res.message === "Automation found") {
        if (res.result) {
          const data = res.result;
  
          setAutomationName(data.automationName);
          setDelayCount(data.delayTime??"");
          setDelayTime(data.delayType??"");

          
          const triggerOption = dropdownOptions.find(
            (option) => option.index === data.triggerType
          );
          setSelectedTrigger({
            name: data.triggerType ?? "Select Condition",
            value: triggerOption ? triggerOption.option : "Select Condition",
          });

          if (data?.triggerGroup?.length > 0) {
            const selectedIds = data.triggerGroup.map(item => ({
              id: item._id,
              name: item.resolvedName,
            }));
            setAllSelectedIds(selectedIds);
            bindSelectedIds(data?.triggerType,selectedIds);
          }
          selectGroup(data.triggerType);
          if(data?.emailTempelate){
            const template = data?.emailTempelate;
            setSelectedTemplate({id: template?._id, name:template?.templateSubject})
          }
        }
      }
    }).catch((err)=>{
      console.log(err,"Error getting data");
    }).finally(()=>{
      setIsLoader(false);
      setIsEditing(true);
    });
  };
  const bindSelectedIds = (option,ids) => {
    if (option === "Courses") {
      setSelectedCourseIds(ids);
    } else if (option === "Challenges") {
      setSelectedChallengeIds(ids);
    } else if (option === "Event") {
      setSelectedEventIds(ids);
    } else if (option === "SubAdminSubs") {
      setSelectedSubsLevels(ids);
    }
  }
  useEffect(() => {
    getSubscriptions();
    fetchRecentCourses();
    fetchRecentChallenges();
    fetchAllEvents();
    getTemplatesEmails();
  }, [id]);

  useEffect(() => {
    groupListForCourses(courseList);
  }, [selectedCourseIds]);

  useEffect(() => {
    groupListForChallenges(challengesList);
  }, [selectedChallengeIds]);

  useEffect(() => {
    groupListForEvents(eventsList);
  }, [selectedEventIds]);

  useEffect(() => {
    groupListForSubscriptions(subsLevel);
  }, [selectedSubsLevels]);

  const getTemplatesEmails = async () => {
    await getEmailTemplate().then((data) => {
      setAllTemplate(data.result);
    });
  };

  const getSubscriptions = async () => {
    getAllsubscription().then((res) => {
      let newArray = [];
      res.result.map((item) => {
        getAllMemberThatBuyMembership(item._id).then((i) => {
          item.memberCount = i.result.validSubs.length;

          newArray.push(item);
          newArray = newArray.sort((a, b) => {
            const dateA = new Date(a.name);
            const dateB = new Date(b.name);
            return dateA - dateB;
          });
          setSubsLevel(newArray);
        });
      });
    });
  };
  const fetchAllEvents = async () => {
    const data = await getAllEvents();
    if (data.result) {
      setEventsList(data.result);
    }
  };
  const fetchRecentChallenges = async () => {
    const datas = await getRecentChallenges();
    if (datas?.result?.r1) {
      var currentDate = new Date();
      const filteredResults = datas.result.r1.filter((item) => {
        if (!item.schedule) {
          return true;
        }

        const originalDate = new Date(item.schedule);
        const selectedTimeZone = localStorage.getItem("websiteTimeZone");
        const convertedDate = originalDate.toLocaleString("en-US", {
          timeZone: selectedTimeZone,
          hour12: true,
        });

        var scheduleDate = new Date(convertedDate);
        return scheduleDate <= currentDate;
      });
      setChallengesList(filteredResults);
    }
  };

  async function fetchRecentCourses() {
    const datas = await getRecentCourses();

    if (datas?.result?.r1) {
      var currentDate = new Date();
      const filteredResults = datas.result.r1.filter((item) => {
        if (!item.schedule && item.tUser > 0) {
          return true;
        }

        const originalDate = new Date(item.schedule);
        const selectedTimeZone = localStorage.getItem("websiteTimeZone");
        const convertedDate = originalDate.toLocaleString("en-US", {
          timeZone: selectedTimeZone,
          hour12: true,
        });

        var scheduleDate = new Date(convertedDate);
        return scheduleDate <= currentDate && item.tUser > 0;
      });

      setCourseList(filteredResults);
    }
  }
  const selectIdsForGroups = (id, name, getSelectedIds, setSelectedIds) => {
    const tempIds = [...getSelectedIds()];
    const existingItem = tempIds.find((item) => item.id === id);

    if (!existingItem) {
      tempIds.push({ id, name });
      setSelectedIds(tempIds);
      setAllSelectedIds(tempIds);
    } else {
      const filteredItems = tempIds.filter((item) => item.id !== id);
      setSelectedIds(filteredItems);
      setAllSelectedIds(filteredItems);
    }
  };
  const groupList = (data, type, getSelectedIds, setSelectedIds, getName) => {
    if (data) {
      const selectedIds = getSelectedIds();
      const datas = data.map((item) => {
        const name = getName(item);
        const checked = selectedIds.some((ele) => ele.id === item._id);

        return (
          <div className="access-level mb-1" key={item._id}>
            <div className="email-custom-checkbox">
              <input
                type="checkbox"
                checked={checked}
                id={item._id}
                name={item._id}
                onChange={() =>
                  selectIdsForGroups(
                    item._id,
                    name,
                    getSelectedIds,
                    setSelectedIds
                  )
                }
              />
              <label
                className="email-custom-checkbox"
                htmlFor={item._id}
              ></label>
            </div>
            <span className="text-capitalize">
              {name?.length > 25 ? `${name.substring(0, 20)}...` : name}
            </span>
          </div>
        );
      });
      setGroupsData(datas);
    }
  };
  const groupListForCourses = (courseData) => {
    groupList(
      courseData,
      "course",
      () => selectedCourseIds,
      setSelectedCourseIds,
      (item) => item.title
    );
  };

  const groupListForChallenges = (challengeData) => {
    groupList(
      challengeData,
      "challenge",
      () => selectedChallengeIds,
      setSelectedChallengeIds,
      (item) => item.title
    );
  };

  const groupListForEvents = (eventData) => {
    groupList(
      eventData,
      "event",
      () => selectedEventIds,
      setSelectedEventIds,
      (item) => item.name
    );
  };

  const groupListForSubscriptions = (subscriptionData) => {
    groupList(
      subscriptionData,
      "subscription",
      () => selectedSubsLevels,
      setSelectedSubsLevels,
      (item) => item.tier
    );
  };
  const handleSelectionChange = (id) => {
    const type = selectedTrigger.name;
    const tempIds = [...selectedIds];

    const existingCourse = tempIds.find((item) => item.id === id);

    if (existingCourse) {
      const filteredIds = tempIds.filter((item) => item.id !== id);
      setAllSelectedIds([...filteredIds]);

      switch (type) {
        case "Courses":
          setSelectedCourseIds([...filteredIds]);
          break;
        case "Event":
          setSelectedEventIds([...filteredIds]);
          break;
        case "Challenges":
          setSelectedChallengeIds([...filteredIds]);
          break;
        case "SubAdminSubs":
          setSelectedSubsLevels([...filteredIds]);
          break;
        default:
          setAllSelectedIds([]);
          setSelectedCourseIds([]);
          setSelectedEventIds([]);
          setSelectedSubsLevels([]);
      }
    }
  };

  const handleSelect = (option,value) => {
    setAllSelectedIds([]);
    setSelectedTrigger({name:option,value:value});
    selectGroup(option);
  };

  const selectGroup = (option) => {
    if (option === "Courses") {
      groupListForCourses(courseList);
    } else if (option === "Challenges") {
      groupListForChallenges(challengesList);
    } else if (option === "Event") {
      groupListForEvents(eventsList);
    } else if (option === "SubAdminSubs") {
      groupListForSubscriptions(subsLevel);
    }
  };
  const moveToNext = () => {
    setIsLoader(true);
    submitData();
    if (selectedSection === "groups") {
      setSelectedSection("delay");
    } else if (selectedSection === "delay") {
      setSelectedSection("selectTemplate");
    }
  };
  const moveToPrev = () => {
    if (selectedSection === "delay") {
      setSelectedSection("groups");
    } else if (selectedSection === "selectTemplate") {
      setSelectedSection("delay");
    }
  };

  const selectEmailTemplate = (id, name) => {
    if (selectedTemplate.id !== id) {
      const newTemplate = {
        id: id,
        name: name,
      };
      setSelectedTemplate(newTemplate);
    }
  };

  const removeEmail = (id) => {
    if (selectedTemplate.id === id) {
      setSelectedTemplate({});
    }
  };

  const resetState = () => {
    setDelayTime("");
    setDelayCount("");
    setSelectedTemplate({});
    setAllSelectedIds([]);
    setSelectedSubsLevels([]);
    setSelectedCourseIds([]);
    setSelectedChallengeIds([]);
    setSelectedEventIds([]);
    setSelectedTrigger({name:"Select Condition",value:"Select Condition"});
    navigate("/email");
  };

  const submitData = (origin) => {
    const userId = localStorage.getItem("user_id");
    const triggerGroupIds = selectedIds?.map((item) => item.id);

    const data = {
      triggerType: selectedTrigger?.name,
      triggerGroup: triggerGroupIds,
      delayType: delayTime,
      delayTime: delayCount,
      subAdminId: userId,
      emailTempelate: selectedTemplate.id,
      automationName: automationName,
    };
    const validationSchema = Yup.object().shape({
      // automationName: Yup.string().required("AutomationName type is required"),
      // triggerType: Yup.string().required("Condition type is required"),
      // delayType: Yup.string().required(
      //   "Please select delay time (days or hours)"
      // ),
      // delayTime: Yup.string().required("Count for delay time is required"),
      // emailTempelate: Yup.string().required("Select a template email"),
      // triggerGroup: Yup.mixed().test(
      //   "has-items",
      //   "At least one item must be selected",
      //   (value) => Array.isArray(value) && value.length > 0
      // ),
    });

    validationSchema
      .validate(data)
      .then(async (validData) => {
        setIsLoader(true);
        await (isEditing 
          ? updateEmailAutomation(id,validData) 
          : saveEmailAutomation(validData)
        )
          .then((res) => {
            if (res.status === 200) {
              if(origin==="save")
              {setIsSuccess(true);
              setMdlMessage(`${res.message}`);}
            } else {
              setWarningModal(true);
              setMdlMessage(`${res.result.error}. Please try again.`);
              setModalTitle("Unexpected response!!");
              setModalType("error");
            }
          })
          .catch((err) => {
            setWarningModal(true);
            setMdlMessage(`${err}. Please try again.`);
            setModalTitle("Error saving automation!!");
            setModalType("error");
          });
      })
      .catch((err) => {
        setWarningModal(true);
        setMdlMessage(`${String(err.errors[0])}.`);
        setModalTitle("ERROR!!!");
        setModalType("error");
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  return (
    <>
      {isLoader && <Loader />}
      {isSuccess && (
        <SuccessPopup
          message={mdlMessage}
          closeSuccessModel={() => {
            navigate("/email");
            setIsSuccess(false);
            setMdlMessage("");
          }}
        />
      )}
      {showWarningModal && (
        <ConfirmationModal
          type={modalType}
          title={modalTitle}
          message={mdlMessage}
          onConfirm={() => {
            setWarningModal(false);
            setModalTitle("");
            setModalType("");
            setMdlMessage("");
          }}
          // onCancel={() => { setModalTitle(''); setModalType(''); setMdlMessage(''); setWarningModal(false) }}
        />
      )}
      <Header />
      <NewSidebar />
      <div className="content-wrapper admin-courses">
        <div className="content-header mb-4 email-creation pt-0">
          <div className="emailautomation-container">
            <div className="emailautomation-main">
              <div className="px-2 py-1">
                <span
                  className="d-flex align-items-center fs-6 pointer w-25"
                  style={{ color: "#12aeb4" }}
                  onClick={() => navigate("/email")}
                >
                  <FontAwesomeIcon
                    icon={faCircleLeft}
                    color="#12aeb4"
                    style={{ height: "25px", marginRight: ".5rem" }}
                  />{" "}
                  Go Back
                </span>
              </div>
              <div className="emailautomation-display">
                <div
                  className={`display-box ${
                    selectedSection === "groups" ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedSection("groups");
                  }}
                >
                  {selectedIds?.length === 0 ? "Select Condition" :"Selected Conditions:"}
                  <p>
                    {selectedIds.map((item) => (
                      <>
                        {item.name}
                        {", "}
                      </>
                    ))}
                  </p>
                </div>
                <div className="work-flow"></div>
                <div
                  className={`display-box ${
                    selectedSection === "delay" ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedSection("delay");
                  }}
                >
                  <div className="display-delay">
                    <FontAwesomeIcon icon={faClock} />
                    <span>
                      {delayCount !== ""
                        ? `${delayCount} ${delayTime}(s)`
                        : "Set Schedule"}
                    </span>
                  </div>
                </div>
                <div className="work-flow"></div>
                <div
                  className={`display-box pb-0 px-0 ${
                    selectedSection === "selectTemplate" ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedSection("selectTemplate");
                  }}
                >
                  <div className="display-delay px-0">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <div>{selectedTemplate?.name ??  "Select Template"}</div>
                  </div>
                </div>
              </div>
              <div className="emailautomation-buttons">
                <button
                  className="email-btn-custom bg-white"
                  style={{ color: "#12aeb4" }}
                  onClick={resetState}
                >
                  Cancel
                </button>
                <button className="email-btn-custom" onClick={()=>submitData("save")}>
                  Save
                </button>
              </div>
            </div>
            <div className="emailautomation-config">
              {selectedSection === "groups" && (
                <div>
                  <h6>Create Email Automation</h6>
                  <div className="config-content">
                    <div className="form-group mt-1">
                      <label style={{ fontSize: "14px", color: "#333244" }}>
                        Automation Name
                      </label>
                      <input 
                        className="form-control" 
                        type="text" 
                        name="automationName" 
                        value={automationName} 
                        style={{
                          backgroundColor: "white",
                          borderColor: "#ced4da",
                          height: "30px",
                        }}
                        onChange={(e)=>{setAutomationName(e.target.value)}}
                      />
                    </div>
                    <div className="form-group mt-1">
                      <label style={{ fontSize: "14px", color: "#333244" }}>
                        Condition
                      </label>
                      <div class="dropdown">
                        <span
                          class="form-control dropdown-toggle"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {selectedTrigger?.value}
                        </span>
                        <ul
                          class="dropdown-menu w-100 mt-0 py-0"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {dropdownOptions?.map((item, index) => (
                            <li
                              class="dropdown-item"
                              key={index}
                              onClick={() => handleSelect(item?.index,item?.option)}
                            >
                              {item?.option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "#333244" }}
                      >
                        {selectedTrigger.name !== "Select Condition" ? "Select Items" : ''}
                      </label>
                      {groupData?.length > 0 && (
                        <div>
                          {selectedIds.length > 0 && (
                            <div
                              className="form-control"
                              style={{ height: "auto" }}
                            >
                              {selectedIds.map((item) => (
                                <Badge
                                  key={item.id}
                                  variant="info"
                                  className="mr-2"
                                >
                                  {item.name}{" "}
                                  <i
                                    onClick={() =>
                                      handleSelectionChange(item.id)
                                    }
                                    className="fas fa-times-circle"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </Badge>
                              ))}
                            </div>
                          )}
                          {groupData?.length > 0 && (
                            <div className="config-group-data">{groupData}</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {selectedSection === "delay" && (
                <div>
                  <h6>Set Schedule</h6>
                  <div className="config-content">
                    <div className="form-group mt-1">
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "#333244" }}
                      >
                        Wait
                      </label>

                      <div className="d-flex gap-2 align-items-center">
                        <div style={{ width: "45px" }}>
                          <input
                            type="text"
                            name="delay-count"
                            className="form-control p-1"
                            value={delayCount}
                            style={{
                              backgroundColor: "white",
                              borderColor: "#ced4da",
                              height: "30px",
                            }}
                            onChange={(e) => setDelayCount(e.target.value)}
                          />
                        </div>
                        <div class="dropdown w-100">
                          <span
                            class="form-control dropdown-toggle"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            placeholder="day(s)"
                          >
                            {delayTime !== "" ? `${delayTime}(s)` : "Select"}
                          </span>
                          <ul
                            class="dropdown-menu w-100 mt-0 py-0"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li
                              class="dropdown-item"
                              onClick={() => setDelayTime("hour")}
                            >
                              hour(s)
                            </li>
                            <li
                              class="dropdown-item"
                              onClick={() => setDelayTime("day")}
                            >
                              day(s)
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedSection === "selectTemplate" && (
                <div>
                  <h6>Select Template</h6>
                  <div className="config-content">
                    <div className="form-group mt-1">
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "#333244" }}
                      >
                        Templates
                      </label>

                      <div className="d-flex gap-2 align-items-center">
                        <div class="dropdown w-100">
                          <span
                            class="form-control dropdown-toggle"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            placeholder="day(s)"
                          >
                            {selectedTemplate?.name ?? "Select email template"}
                          </span>
                          <ul
                            class="dropdown-menu w-100 mt-0 py-0"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {allTemplates?.length > 0 &&
                              allTemplates?.map((item) => (
                                <li
                                  className="dropdown-item pointer"
                                  onClick={() =>
                                    selectEmailTemplate(
                                      item._id,
                                      item?.templateSubject
                                    )
                                  }
                                >
                                  {item?.templateSubject}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-1">
                      <button
                        className="save-btn text-white"
                        style={{
                          fontSize: "14px",
                          color: "#333244",
                          fontStyle: "bold",
                        }}
                        onClick={() => navigate(`/create-email-template/${id}`)}
                      >
                        Create New Templates
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ padding: "10px", display: "flex", gap: "8px" }}>
                {selectedSection !== "groups" && (
                  <button className="save-btn" onClick={moveToPrev}>
                    Back
                  </button>
                )}
                {selectedSection !== "selectTemplate" && (
                  <button className="save-btn" onClick={moveToNext}>
                    Next
                  </button>
                )}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailAutomation;
