import { useState, useEffect } from "react";
import moment from "moment";
import { getAllUpcomingEvents} from "../../helper/service";
import { Link } from "react-router-dom";

import Loader from "../../Loader";
import ConfirmationModal from "../Setting/ConfirmationModal";
import ViewEventModal from "./ViewEventModal";

const UpcomingEvents = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [eventId, setEventId] = useState('');
  const [paidEvent,setPaidEvent] = useState(false);
  const [message,setMessage] = useState('')
  const [checkoutUrl,setCheckOutUrl]=useState('')
  const [modalType,setModalType]=useState('')
  const [showWarningModal,setWarningModal]=useState(false)
  const [warningModalTitle,setModalTitle]=useState('')

  const formatDate = (orgDate) => {
    let timeZone = moment(orgDate);
    timeZone = timeZone._d.toString().split(" ")[5];
    const time = moment(orgDate).format("HH:mm");
    const date = moment(new Date(orgDate).setUTCHours(new Date(orgDate).getUTCHours() + 1)).format("YYYY-MM-DD");
    return{
      date,
      time,
      timeZone,
    }
  };

  useEffect(() => {
    const getUpcomingEvents = async () => {
      setIsLoader(true);

      try {
        const data = await getAllUpcomingEvents();
        if (data.result.length > 0) {
          let upcomingEventsData = data.result.sort(function (a, b) {
            const firstEventDate = new Date(a.eventDate);
            const secondEventDate = new Date(b.eventDate);
            return firstEventDate - secondEventDate;
          });
                    
          var arr1 = upcomingEventsData.map(function (evnt) {
            const start = formatDate(evnt.eventDate);
            const end = formatDate(evnt.eventEndDate);
            let eventDate = start.date;
            let eventEndDate = end.date;
            let startTime = start.time;
            let endTime = end.time;

            return (
              <div
                key={evnt._id}
                className="event-list"
                onClick={() => {
                  detailsEvent(evnt._id);
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="event-info">
                  <h5 className="event-title">{evnt.name}</h5>
                  <div className="d-flex">
                    <span className="event-time">
                      {eventDate} {startTime} | {eventEndDate} {endTime}
                    </span>
                  </div>
                </div>
              </div>
            );
          }, this);

          setUpcomingEvents(arr1);
        } else {
          setUpcomingEvents(["Currently, there are no upcoming events."]);
        }
      } catch (error) {
        console.error(
          "Something went wrong. Couldn't fetch Upcoming Events. Please try again later."
        );
      } finally {
        setIsLoader(false);
      }
    };

    getUpcomingEvents();
  }, []);

  const detailsEvent = (idd) => {
    setEventId(idd);
    openModal1();
    setIsLoader(true);
  };
  const setWarningModalData = (title,type,message,isShow) => {
    setWarningModal(isShow);
    setModalTitle(title);
    setModalType(type);
    setMessage(message);
  }
  const openModal1 = () => {
    setIsOpen(true);
  };
  const closeModal1 = () => {
    setIsOpen(false);
  };

  const shareEvent = (e,eventId,isPaid) => {
    let msg = '' ,msg1=''
    let id = eventId;
    
    if(isPaid){
      msg = `${process.env.REACT_APP_MEMBER_URL}/events/${id}`;
      msg1 = `${process.env.REACT_APP_MEMBER_URL}/eventcheckout/${id}`;
    }else{
      msg = `${process.env.REACT_APP_MEMBER_URL}/events/${id}`;
    }
    setPaidEvent(isPaid);
    setMessage (msg);
    setCheckOutUrl(msg1);
    setModalType("share");
    setWarningModal(true);
    setModalTitle ("Event Url");
  }
  return (
    <>
    {isLoader ? <Loader/>: <></>}
    {showWarningModal &&<ConfirmationModal
      type={modalType}
      title={warningModalTitle}
      message={message}
      message2={checkoutUrl}
      isPaid={paidEvent}
      onConfirm={() => {
        setPaidEvent(false);
        setMessage ('');
        setCheckOutUrl('');
        setModalType("");
        setWarningModal(false);
        setModalTitle ("");
        setEventId('');
      }}
    />}
      <div className="card-header">
        <h3 className="card-title">Upcoming Events</h3>

        <div className="card-tools">
          <Link to="/events" style={{ color: "#12aeb4" }}>
            View all <i className="fas fa-angle-right" />
          </Link>
        </div>
      </div>
      <div className="dashboard upcoming-event">
        <div
          className={
            upcomingEvents?.length > 3 ? "events-scroll" : "pr-2 border-right"
          }
        >
          {upcomingEvents}
        </div>
      </div>
      {/* View Event */}
      {isOpen && <ViewEventModal eventId = {eventId} shareEvent={shareEvent} onClose={closeModal1} setLoader={(value)=>setIsLoader(value)} setWarningModal={setWarningModalData} formatDate={formatDate} />}      
    </>
  );
};
export default UpcomingEvents;